<template>
  <div class="mzlr">
    <div class="userinfo" v-if="user_detail?.sjhm">
      <div class="name">{{ user_detail?.xm }}</div>
      <div class="phone">{{ (user_detail?.sjhm.substring(0, 4) + '****' + user_detail?.sjhm.substring(8, 11)) }}</div>
    </div>
    <div class="main">
      <div class="box">
        <div class="head">
          <img src="@/assets/img/zmlr.png" class="title-img" alt="">
          <span class="title">手腕数据</span>
        </div>
        <div class="inner">
          <van-cell-group inset>
            <div class="cells">
              <div class="cell">
                <van-field v-model="form.sw_zuo_maibo" type="digit" placeholder="请输入" label="左脉搏" />
              </div>
              <div class="cell">
                <van-field v-model="form.sw_you_maibo" type="digit" placeholder="请输入" label="右脉搏" />
              </div>
              <div class="cell">
                <van-field v-model="form.sw_zuo_diya" type="digit" placeholder="请输入" label="左低压" />
              </div>
              <div class="cell">
                <van-field v-model="form.sw_you_diya" type="digit" placeholder="请输入" label="右低压" />
              </div>
              <div class="cell">
                <van-field v-model="form.sw_zuo_gaoya" type="digit" placeholder="请输入" label="左高压" />
              </div>
              <div class="cell">
                <van-field v-model="form.sw_you_gaoya" type="digit" placeholder="请输入" label="右高压" />
              </div>
            </div>
          </van-cell-group>
        </div>
      </div>
      <div class="box">
        <div class="head">
          <img src="@/assets/img/zmlr.png" class="title-img" alt="">
          <span class="title">上臂数据</span>
        </div>
        <div class="inner">
          <van-cell-group inset>
            <div class="cells">
              <div class="cell">
                <van-field v-model="form.sb_zuo_maibo" type="digit" placeholder="请输入" label="左脉搏" />
              </div>
              <div class="cell">
                <van-field v-model="form.sb_you_maibo" type="digit" placeholder="请输入" label="右脉搏" />
              </div>
              <div class="cell">
                <van-field v-model="form.sb_zuo_diya" type="digit" placeholder="请输入" label="左低压" />
              </div>
              <div class="cell">
                <van-field v-model="form.sb_you_diya" type="digit" placeholder="请输入" label="右低压" />
              </div>
              <div class="cell">
                <van-field v-model="form.sb_zuo_gaoya" type="digit" placeholder="请输入" label="左高压" />
              </div>
              <div class="cell">
                <van-field v-model="form.sb_you_gaoya" type="digit" placeholder="请输入" label="右高压" />
              </div>
            </div>
          </van-cell-group>
        </div>
      </div>
    </div>
    <div class="footer">
      <VanButton type="primary" block @click="tapSubmit">保存提交</VanButton>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { showNotify } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Mzlr',
  data() {
    return {
      form: {
        sw_zuo_maibo: '',
        sw_you_maibo: '',
        sw_you_maibo: '',
        sw_you_maibo: '',
        sw_zuo_gaoya: '',
        sw_zuo_gaoya: '',
        sw_zuo_gaoya: '',
        sb_you_maibo: '',
        sb_you_maibo: '',
        sb_you_diya: '',
        sb_zuo_gaoya: '',
        sb_you_gaoya: '',
      }
    }
  },
  created() {
    this.get_user_detail({ sjhm: this.$route.query.sjhm });
  },
  computed: {
    ...mapState(['user_detail']),
  },
  methods: {
    ...mapActions(['submit_maizhen_data', 'get_user_detail']),
    getShortTime(time, start, end) {
      if (end) {
        return time.substring(start, end);
      }
      else return time.substring(start);
    },
    checkData() {
      if (!Object.keys(this.form).every(key => {
        if (this.form[key]) return true;
      })) {
        showNotify({ type: 'warning', message: '请填写完整数据' })
        return false;
      }
      return true;
    },
    async tapSubmit() {
      if (!this.checkData()) {
        return;
      }
      let { data: res } = await this.submit_maizhen_data({ ...this.form, sjhm: this.$route.query.sjhm, sfbs: this.$route.query.sfbs });
      if (res.success) {
        showNotify({ type: 'success', message: res.msg || '操作成功' })
        this.form = {
          sw_zuo_maibo: '',
          sw_you_maibo: '',
          sw_you_maibo: '',
          sw_you_maibo: '',
          sw_zuo_gaoya: '',
          sw_zuo_gaoya: '',
          sw_zuo_gaoya: '',
          sb_you_maibo: '',
          sb_you_maibo: '',
          sb_you_diya: '',
          sb_zuo_gaoya: '',
          sb_you_gaoya: '',
        };
      }
      else {
        showNotify({ type: 'danger', message: res.msg || '操作失败' })
      }
    }
  },

}
</script>
<style lang="less" scoped>
.mzlr {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow:auto;

  .userinfo {
    flex: 0;
    width: 100%;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .name {
      padding: 12px 0;
      font-size: 18px;
      color: #000;
    }

    .phone {
      height: 32px;
      line-height: 32px;
      padding: 0 24px;
      font-size: 16px;
      color: #fff;
      border-radius: 16px;
      background: #3fb6c6;
    }
  }

  .main {
    flex: 1;
    width: 100%;

    .box {
      background: #fff;
      border-radius: 10px;
      margin: 12px;

      .head {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        padding-bottom: 12px;
        height: 36px;

        .title {
          position: relative;
          font-size: 18px;
          color: #fff;
        }

        .title-img {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 36px;
        }
      }

      .inner {
        .cells {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          .cell {
            height: 62px;
            width: 50%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ededed;
            flex: 1 0 auto;

            &:nth-last-child(1) {
              border: none;
            }

            &:nth-last-child(2) {
              border: none;
            }
          }
        }
      }
    }
  }

  .footer {
    width: calc(100% - 24px);
    margin: 12px
  }
}
</style>
